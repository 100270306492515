body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overscroll-behavior-y: contain; */
  background-color: #bfc6c9;

}
html{
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
.slick-list {margin: 0 -5px;}
.slick-slide>div {padding: 0 8px;}

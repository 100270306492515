.travel-style-selection-screen {
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Ensure the items are spaced evenly */
  align-items: center;
  width: 90%;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  background-color: #BFC6C9;
  border-radius: 10px;
}

.style-button{
  padding: 10px;
  width: 340px;
  font-size: 18px;
  background-color: #fff;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selected-style {
  background-color: #FF8C00; /* Green */
  color: white;
}
.selection-area {
  flex: 3;
}

.selection-title {
  font-weight: bold;
  margin-bottom: 20px;
}

.progress-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.progress-title {
  font-weight: bold;
  margin-bottom: 20px;
}

.next-button-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .travel-style-selection-screen {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .progress-area {
    margin-left: 0;
    margin-top: 20px;
  }
}
.button-container {
  display: flex;        /* Ensures the container uses flexbox */
  justify-content: flex-start; /* Aligns children (the button) to the start (left) */
  width: 100%;          /* Takes full width to maintain layout consistency */
  padding-left: 20px;   /* Adds some padding on the left if needed */
}

.next-button {
  padding: 20px;
  width: 300px;          /* Adjust width to content or set a specific width */
  font-size: 18px;
  color: black;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s ease;
}


.next-button:hover {
  background-color: #FFA500; /* Lighter yellow on hover for interactive feedback */
  color: white;
  
}

.destination-selection-screen {
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* Ensure the items are spaced evenly */
    align-items: left;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    background-color: #d3d3d3;
    border-radius: 10px;
  }
  
  .selection-area {
    flex: 3;
  }
  
  .selection-title {
    font-weight: bold;
    margin-bottom: 20px;
    align-content: left;
  }
  
  .selection-form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
  }
  
  .next-button-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  
  .progress-area {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
  }
  
  .progress-title {
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    .destination-selection-screen {
      flex-direction: column;
      align-items: center;
      padding: 20px;
    }
  
    .progress-area {
      margin-left: 0;
      margin-top: 20px;
    }
    .selection-title {
      padding-top: 60px;
    }
  }
  

  .custom-range-picker .ant-picker-input > input {
    height: 48px; /* Increase input field height */
  }
  
  .custom-range-picker .ant-picker-input {
    align-items: center; /* Adjust alignment if necessary */
  }
.main-choice-screen {
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    align-items: center;
    width: 90%;
    max-width: 1300px;
    margin: auto;
    padding: 20px;
    background-color: #d3d3d3;
    border-radius: 10px;
  }
  
  .choice-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    margin-right: auto; /* Pushes the section to the left */
  }
  
  .choice-button {
    padding: 20px;         
    width: 230%;            
    max-width: 300px;          
    font-size: 16px;           
    color: #000;               
    background-color: #fff;     
    border: 1px solid #161616;
    border-radius: 5px;         
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
    font-weight: bold;          
    text-align: center;        
    transition: background-color 0.3s ease; 
    margin: 30px 0;             
  }
  
  .choice-button:hover {
    background-color: #FFA500; /* Change to yellow on hover */
  }
  
  .or-text {
    font-weight: bold;
    margin: 20px 0; /* Adjusted margin to provide spacing */
  }
  
  .progress-indicator-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
  }
  
  @media (max-width: 768px) {
    .main-choice-screen {
      flex-direction: column;
      padding: 20px;
    }
  
    .progress-indicator-section {
      margin-left: 0;
      margin-top: 20px;
    }
    .choice-button:hover {
      background-color: white; /* Change to yellow on hover */
    }
    
  }
 
  
  .choice-button.selected {
    background-color: #FFA500; ;
    color: white;
    border-color:#FFA500; ;
    border: 1px solid #161616;

  }
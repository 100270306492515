.country-selection-screen {
    padding: 2px;
    padding-top: 70px;
  }
  
  .country-group {
    margin-bottom: 20px;
  }
  
  .region-title {
    font-weight: bold;
    margin-bottom: 10px;
  }
  .country-button {
    width: 100%;
    height: 100%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #161616;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    color: black;
  }
  
  .country-button.selected {
    border-color: #FFA500;
    background-color: #FFA500;
    color: #fff;
    border: 1px solid #161616;
  }
  
  .country-button:hover {
    background-color: #FFA500;
  }
  
  .progress-indicator-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .selection-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 86%;
    max-width: 1300px;
    padding: 20px;
    border-radius: 10px;
    margin: auto;

  }
  
  .selection-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start; 
    width: 100%;
    gap: 120px;
  }
  
  
  .selection-section {
    flex: 3;
  }
  
  .progress-indicator-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
  }
  
  @media (max-width: 768px) {
    .selection-content {
      flex-direction: column;
      gap: 10px;
    }
    .country-button:hover {
      background-color: white;
    }
    .country-button.selected {
      border-color: #FFA500;
      background-color: #FFA500;
      color: #fff;
    }
    
    .progress-indicator-section {
      margin-left: 0;
      margin-top: 20px;
    }
  }
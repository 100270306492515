.travel-style-selection-screen {
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Ensure the items are spaced evenly */
  align-items: center;
  width: 90%;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  background-color: #d3d3d3;
  border-radius: 10px;
}

.selection-area {
  flex: 3;
}

.selection-title {
  font-weight: bold;
  margin-bottom: 20px;
}

.progress-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.progress-title {
  font-weight: bold;
  margin-bottom: 20px;
}

.next-button-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .travel-style-selection-screen {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .progress-area {
    margin-left: 0;
    margin-top: 20px;
  }
}

.map-view {
    background-color: #e0e0e0;
    /* padding: 20px; */
    /* border-radius: 10px; */
  }
  
  .map-view img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  .gm-style-iw .gm-style-iw-c {
    padding: 6px !important; /* Correcting syntax by removing quotes around value */
    
  }
.gm-ui-hover-effect {
  display: none !important;
}
.gm-style-iw-ch {
  padding-top: 0 !important; /* Removes default padding and ensures the rule takes precedence */
}

.getaway-selection-screen {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 90%;
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    background-color: #BFC6C9;
    border-radius: 10px;
  }
  
  .selection-area {
    flex: 3;
  }
  
  .selection-title {
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .options-grid {
    display: flex;
    flex-wrap: wrap;
  }
  
  .option-button {
    padding: 20px;              
    width: 100%;                 
    max-width: 400px;          
    font-size: 16px;            
    color: #000;              
    background-color: #fff;    
    border: 1px solid #161616;
    border-radius: 5px;        
    cursor: pointer;
    font-weight: bold;      
    text-align: center;         
    transition: background-color 0.3s ease; 
    margin: 30px 0;             
  }
  
  .option-button:hover {
    background-color: #FF8C00;
  }
  
  .progress-area {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
  }
  
  .progress-title {
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .next-button-container {
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    .getaway-selection-screen {
      flex-direction: column;
      align-items: center;
      padding: 20px 0px 20px 0px;
    }
  
    .progress-area {
      margin-left: 0;
      margin-top: 20px;
    }
    .option-button{
      margin: 10px 0;
    }
    .option-button:hover {
      background-color: white;
    }
  .selection-area {
    /* flex: 3; */
    padding-top: 20px;
  }
  .selection-title {
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }
  .option-button {
    padding: 14px;              
    width: 100%;                 
    max-width: 400px;          
    font-size: 13px;            
    color: #000;              
    background-color: #fff;    
    border: 1px solid #161616;
    border-radius: 4px;        
    cursor: pointer;
    font-weight: 600;      
    text-align: center;         
    transition: background-color 0.3s ease; 
    margin: 1px 0;             
  }
  
  }
  .option-button.selected {
    background-color: #FF8C00; /* Green */
    color: white;
  }
  
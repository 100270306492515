.trip-summary {
    background-color: #BFC6C9;
    padding: 20px;
    border-radius: 0px;
    padding-top: 100px;
    /* height: 100%; */
 
  }
  
  .trip-summary h2 {
    margin-bottom: 10px;
  }
  
  .trip-details {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .trip-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    /* font-weight: 400; */
  }
  
  .re-generate {
    background-color: #000000;
    color: white;
  }
  
  .new-trip {
    background-color: #000000;
    color: white;
  }
  
  .save {
    background-color: #000000;
    color: white;
  }
  .trip-planner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* gap: 20px;
    padding: 20px; */
  }
  .map-view {
    order: 2; /* Ensure map is on the right */
  }
  .trip-summary {
    order: 1; /* Ensure summary is on the left */
  
  }
  @media (max-width: 768px) {
    .trip-planner {
      grid-template-columns: 1fr; /* Stack items in one column on smaller screens */
      /* display: flex;
      flex-direction: column; */
    }

    .trip-planner .map-view {
      /* height: 400px; */
      order:1; 
    
    }
    .trip-summary{
      padding-top: 100%;
    }
  }
 
.transit-card {
    border: 1px solid transparent;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Subtle shadow for depth */
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    margin: 10px;
    cursor: pointer;
    background-color: #fff;
    
  }
  

  
  .transit-card-content {
    background-color: #000;
    color: #fff;
    text-align: center;
    padding: 10px;
  }
  
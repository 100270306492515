.main-button-screen {
  height: 100vh;  /* Ensures full viewport height */
  width: 100vw;   /* Ensures full viewport width */
  background-image: url('https://res.cloudinary.com/dyidgwoxx/image/upload/f_auto,q_auto/f6kp2pgsqhpx2pyioo6t'); /* Path to your local image */
  background-size: cover;   /* Cover the entire screen */
  background-repeat: no-repeat; /* No repeating the background */
  background-position: top center; /* Align the background to the top center */
  display: flex;
  justify-content: center; /* Center the button horizontally */
  align-items: center; /* Center the button vertically */
  margin: 0; /* Remove any default margin */
  padding: 0; /* Remove any default padding */
  min-height: 100vh;
}

.main-button {
  padding: 20px;
  width: 80%;
  max-width: 400px;
  font-size: 16px; /* Default font size */
  color: #000; /* Black text for better visibility */
  background-color: #FFFFFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s ease;
}

.main-button:hover {
  background-color: #FFA500; /* Color changes on hover */
  color: #000; /* Text color changes on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .main-button {
    font-size: 16px; /* Smaller font size for smaller screens */
    padding: 20px; /* Slightly smaller padding */
  }
}

@media (max-width: 480px) {
  .main-button {
    font-size: 16px; /* Even smaller font size for mobile screens */
    padding: 18px; /* Reduce padding to fit smaller screens */
    width: 85%; /* Wider button relative to screen size for easier interaction */
  }
}

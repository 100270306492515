/* src/components/ContinentSelection.css */
.selection-screen {
    padding: 20px;
    text-align: left;
    /* display: block; */
  }
  
  .continent-selection-title {
    font-weight: bold;
    text-align: left;
    padding: 20px 0;
  }
  
  
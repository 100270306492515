.App {
  text-align: center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  /* padding: 20px; */
  background-color: #BFC6C9;
}

.selection-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 1300px;
  padding: 20px;
  border-radius: 10px;
  margin: auto;
}

.selection-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; 
  width: 100%;
  gap: 120px;
}


.selection-section {
  flex: 3;
}

.progress-indicator-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

@media (max-width: 768px) {
  .selection-content {
    flex-direction: column;
    gap: 10px;
  }

  .progress-indicator-section {
    margin-left: 0;
    margin-top: 20px;
  }
}
